import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { fetchChannelsAsync, setSelectedChannel, setChannels } from './store/channelSlice';
import { RootState, AppDispatch } from './store';
import Home from './pages/Home';
import ChannelStatistics from './Page/Telegram/Statistic/ChannelStatistics';
import Announcements from './pages/Announcements';
import LoginPage from './pages/LoginPage';
import HandleAuth from './pages/HandleAuth';
import PrivateRoute from './components/PrivateRoute';
import ChannelLayout from './Page/Layout/ChannelLayout';
import './App.css';
import CreateAnnouncement from "./pages/CreateAnnouncement";
import CabinetsStatistics from "./pages/CabinetsStatistics";
import AdsStatistics from "./pages/AdsStatistics";

const App: React.FC = () => {
    const dispatch = useDispatch<AppDispatch>();
    const authData = useSelector((state: RootState) => state.auth);
    const { channels, selectedChannel } = useSelector((state: RootState) => state.channels);

    useEffect(() => {
        const savedChannels = localStorage.getItem('channels');
        const savedChannelId = localStorage.getItem('selectedChannelId');

        if (savedChannels) {
            dispatch(setChannels(JSON.parse(savedChannels)));
        }

        if (savedChannelId) {
            dispatch(setSelectedChannel(Number(savedChannelId)));
        }

        if (authData && !savedChannels) {
            dispatch(fetchChannelsAsync());
        }
    }, [authData, dispatch]);

    useEffect(() => {
        if (channels.length > 0) {
            localStorage.setItem('channels', JSON.stringify(channels));
        }
    }, [channels]);

    useEffect(() => {
        if (selectedChannel) {
            localStorage.setItem('selectedChannelId', selectedChannel.toString());
        }
    }, [selectedChannel]);

    const handleChannelChange = (channelId: number) => {
        dispatch(setSelectedChannel(channelId));
    };

    return (
        <Router>
            <Routes>
                <Route path="/login" element={<LoginPage />} />
                <Route path="/handleAuth" element={<HandleAuth />} />
                <Route element={<PrivateRoute />}>
                    <Route path="/" element={
                        <ChannelLayout
                            channels={channels.map(channel => ({ id: channel.id.toString(), name: channel.name }))}
                            selectedChannel={selectedChannel?.toString() || null}
                            onChannelChange={(channelId: string) => handleChannelChange(Number(channelId))}
                        >
                            <Home />
                        </ChannelLayout>
                    } />
                    <Route path="/statistics/finance" element={
                        <ChannelLayout
                            channels={channels.map(channel => ({ id: channel.id.toString(), name: channel.name }))}
                            selectedChannel={selectedChannel?.toString() || null}
                            onChannelChange={(channelId: string) => handleChannelChange(Number(channelId))}
                        >
                            <ChannelStatistics selectedChannel={selectedChannel} />
                        </ChannelLayout>
                    } />
                    <Route path="/statistics/cabinets" element={
                        <ChannelLayout
                            channels={channels.map(channel => ({ id: channel.id.toString(), name: channel.name }))}
                            selectedChannel={selectedChannel?.toString() || null}
                            onChannelChange={(channelId: string) => handleChannelChange(Number(channelId))}
                        >
                            <CabinetsStatistics />
                        </ChannelLayout>
                    } />
                    <Route path="/statistics/ads" element={
                        <ChannelLayout
                            channels={channels.map(channel => ({ id: channel.id.toString(), name: channel.name }))}
                            selectedChannel={selectedChannel?.toString() || null}
                            onChannelChange={(channelId: string) => handleChannelChange(Number(channelId))}
                        >
                            <AdsStatistics />
                        </ChannelLayout>
                    } />
                    <Route path="/announcements" element={
                        <ChannelLayout
                            channels={channels.map(channel => ({ id: channel.id.toString(), name: channel.name }))}
                            selectedChannel={selectedChannel?.toString() || null}
                            onChannelChange={(channelId: string) => handleChannelChange(Number(channelId))}
                        >
                            <Announcements selectedChannel={selectedChannel} />
                        </ChannelLayout>
                    } />
                    <Route path="/create-announcement" element={
                        <ChannelLayout
                            channels={channels.map(channel => ({ id: channel.id.toString(), name: channel.name }))}
                            selectedChannel={selectedChannel?.toString() || null}
                            onChannelChange={(channelId: string) => handleChannelChange(Number(channelId))}
                        >
                            <CreateAnnouncement selectedChannelId={selectedChannel} />
                        </ChannelLayout>
                    } />
                </Route>
            </Routes>
        </Router>
    );
};

export default App;
