import React, { useEffect, useState } from 'react';
import { Table, Spin, DatePicker, Select, Button, Row, Col, Checkbox, Dropdown, Menu } from 'antd';
import { fetchCabinetStatistics, fetchChannels } from '../api/api';
import { CabinetStatisticsRequest, CabinetStatisticsResponse, Channel } from '../api/apiModels';
import dayjs, { Dayjs } from 'dayjs';

const { RangePicker } = DatePicker;
const { Option } = Select;

interface CabinetData {
    key: string;
    date: string;
    cabinet: string;
    expenses: number;
    views: number;
    clicks: number;
    subscription: number;
    ctr: number;
    cpm: number;
    cpc: number;
    cr: number;
}

const CabinetsStatistics: React.FC = () => {
    const [data, setData] = useState<CabinetData[]>([]);
    const [loading, setLoading] = useState(false);
    const [dateRange, setDateRange] = useState<[Dayjs, Dayjs]>([dayjs().subtract(1, 'month'), dayjs()]);
    const [selectedChannels, setSelectedChannels] = useState<string[]>([]);
    const [channels, setChannels] = useState<Channel[]>([]);
    const [selectedColumns, setSelectedColumns] = useState<string[]>([
        'date',
        'cabinet',
        'expenses',
        'views',
        'clicks',
        'subscription',
        'ctr',
        'cpm',
        'cpc',
        'cr',
    ]);

    useEffect(() => {
        const fetchChannelsList = async () => {
            const response = await fetchChannels();
            setChannels(response);
        };
        fetchChannelsList();
    }, []);

    const handleFetchData = async () => {
        setLoading(true);
        const requestData: CabinetStatisticsRequest = {
            startDate: dateRange[0].format('YYYY-MM-DD'),
            endDate: dateRange[1].format('YYYY-MM-DD'),
            channels: selectedChannels,
        };
        const response: CabinetStatisticsResponse = await fetchCabinetStatistics(requestData);
        console.log(response);
        setData(response.map((item, index) => ({ ...item, key: index.toString() })));
        setLoading(false);
    };

    const allColumns = [
        {
            title: 'Дата',
            dataIndex: 'date',
            key: 'date',
            sorter: (a: CabinetData, b: CabinetData) => dayjs(a.date).diff(dayjs(b.date)),
        },
        {
            title: 'Кабинет',
            dataIndex: 'cabinet',
            key: 'cabinet',
            render: (text: string) => <div dangerouslySetInnerHTML={{ __html: text }} />,
        },
        {
            title: 'Расходы',
            dataIndex: 'expenses',
            key: 'expenses',
            sorter: (a: CabinetData, b: CabinetData) => a.expenses - b.expenses,
        },
        {
            title: 'Просмотры',
            dataIndex: 'views',
            key: 'views',
            sorter: (a: CabinetData, b: CabinetData) => a.views - b.views,
        },
        {
            title: 'Клики',
            dataIndex: 'clicks',
            key: 'clicks',
            sorter: (a: CabinetData, b: CabinetData) => a.clicks - b.clicks,
        },
        {
            title: 'Подписки',
            dataIndex: 'subscription',
            key: 'subscription',
            sorter: (a: CabinetData, b: CabinetData) => a.subscription - b.subscription,
        },
        {
            title: 'CTR %',
            dataIndex: 'ctr',
            key: 'ctr',
            sorter: (a: CabinetData, b: CabinetData) => a.ctr - b.ctr,
        },
        {
            title: 'CPM 💎',
            dataIndex: 'cpm',
            key: 'cpm',
            sorter: (a: CabinetData, b: CabinetData) => a.cpm - b.cpm,
        },
        {
            title: 'CPC 💎',
            dataIndex: 'cpc',
            key: 'cpc',
            sorter: (a: CabinetData, b: CabinetData) => a.cpc - b.cpc,
        },
        {
            title: 'CR',
            dataIndex: 'cr',
            key: 'cr',
            sorter: (a: CabinetData, b: CabinetData) => a.cr - b.cr,
        },
    ];

    const handleMenuClick = (e: any) => {
        const columnKey = e.key;
        if (selectedColumns.includes(columnKey)) {
            setSelectedColumns(selectedColumns.filter(key => key !== columnKey));
        } else {
            setSelectedColumns([...selectedColumns, columnKey]);
        }
    };

    const menu = (
        <Menu onClick={handleMenuClick}>
            {allColumns.map(col => (
                <Menu.Item key={col.key}>
                    <Checkbox checked={selectedColumns.includes(col.key)}>{col.title}</Checkbox>
                </Menu.Item>
            ))}
        </Menu>
    );

    const filteredColumns = allColumns.filter(col => selectedColumns.includes(col.key));

    return (
        <div>
            <h1>Статистика по кабинетам</h1>
            <Row gutter={16} style={{ marginBottom: 16 }} align="middle">
                <Col>
                    <RangePicker
                        defaultValue={dateRange}
                        onChange={(dates) => dates && setDateRange([dates[0]!, dates[1]!])}
                        style={{ marginRight: 16 }}
                    />
                </Col>
                <Col>
                    <Button type="primary" onClick={handleFetchData} style={{ marginRight: 16 }}>
                        Получить данные
                    </Button>
                </Col>
                <Col>
                    <Dropdown overlay={menu}>
                        <Button>
                            Выберите столбцы
                        </Button>
                    </Dropdown>
                </Col>
            </Row>
            <Row style={{ marginBottom: 16 }}>
                <Col span={24}>
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Выберите каналы"
                        onChange={setSelectedChannels}
                    >
                        {channels.map(channel => (
                            <Option key={channel.id} value={channel.id}>
                                <div dangerouslySetInnerHTML={{ __html: channel.name }} />
                            </Option>
                        ))}
                    </Select>
                </Col>
            </Row>
            {loading ? <Spin /> : <Table dataSource={data} columns={filteredColumns} />}
        </div>
    );
};

export default CabinetsStatistics;
