import React, { useEffect, useState } from 'react';
import { Table, Spin } from 'antd';

interface AdData {
    key: string;
    ad: string;
    views: number;
    clicks: number;
    conversions: number;
}

const AdsStatistics: React.FC = () => {
    const [data, setData] = useState<AdData[]>([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Здесь можно сделать запрос к API для получения данных
        setTimeout(() => {
            // Пример данных
            setData([
                { key: '1', ad: 'Объявление 1', views: 512, clicks: 128, conversions: 15 },
                { key: '2', ad: 'Объявление 2', views: 1024, clicks: 256, conversions: 25 },
                // Добавьте больше данных по необходимости
            ]);
            setLoading(false);
        }, 1000);
    }, []);

    const columns = [
        {
            title: 'Объявление',
            dataIndex: 'ad',
            key: 'ad',
        },
        {
            title: 'Просмотры',
            dataIndex: 'views',
            key: 'views',
        },
        {
            title: 'Клики',
            dataIndex: 'clicks',
            key: 'clicks',
        },
        {
            title: 'Конверсии',
            dataIndex: 'conversions',
            key: 'conversions',
        },
    ];

    return (
        <div>
            <h1>Статистика по объявлениям</h1>
            {loading ? <Spin /> : <Table dataSource={data} columns={columns} />}
        </div>
    );
};

export default AdsStatistics;
