import React, { useState, useRef, useEffect } from 'react';
import { Table, Button } from 'antd';
import './AdScheduler.css'; // Импортировать стили

const daysOfWeek = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'];
const hours = Array.from({ length: 24 }, (_, i) => i);

interface CellData {
    day: string;
    hour: number;
    selected: boolean;
}

const hourValues: Record<number, number> = {
    0: 1,
    1: 2,
    2: 4,
    3: 8,
    4: 16,
    5: 32,
    6: 64,
    7: 128,
    8: 256,
    9: 512,
    10: 1024,
    11: 2048,
    12: 4096,
    13: 8192,
    14: 16384,
    15: 32768,
    16: 65536,
    17: 131072,
    18: 262144,
    19: 524288,
    20: 1048576,
    21: 2097152,
    22: 4194304,
    23: 8388608,
};

interface AdSchedulerProps {
    onSave: (schedule: string) => void;
    onSelectionChange: (schedule: string) => void;
}

const AdScheduler: React.FC<AdSchedulerProps> = ({ onSave, onSelectionChange }) => {
    const [cells, setCells] = useState<CellData[][]>(
        daysOfWeek.map(day => hours.map(hour => ({ day, hour, selected: false })))
    );
    const [selectedCells, setSelectedCells] = useState<Record<string, number>>({});
    const isMouseDownRef = useRef(false);

    const handleCellClick = (day: string, hour: number) => {
        setCells(prevCells =>
            prevCells.map(row =>
                row.map(cell =>
                    cell.day === day && cell.hour === hour
                        ? { ...cell, selected: !cell.selected }
                        : cell
                )
            )
        );
    };

    const handleMouseDown = () => {
        isMouseDownRef.current = true;
    };

    const handleMouseUp = () => {
        isMouseDownRef.current = false;
    };

    const handleMouseOver = (day: string, hour: number) => {
        if (isMouseDownRef.current) {
            setCells(prevCells =>
                prevCells.map(row =>
                    row.map(cell =>
                        cell.day === day && cell.hour === hour
                            ? { ...cell, selected: !cell.selected }
                            : cell
                    )
                )
            );
        }
    };

    useEffect(() => {
        const selected: Record<string, number> = {};
        cells.flat().forEach(cell => {
            if (cell.selected) {
                if (!selected[cell.day]) {
                    selected[cell.day] = 0;
                }
                selected[cell.day] += hourValues[cell.hour];
            }
        });
        setSelectedCells(selected);

        const selectedValuesString = daysOfWeek.map(day => {
            const dayCells = cells.find(row => row[0].day === day);
            return dayCells?.filter(cell => cell.selected).map(cell => cell.hour).join(',') || '0';
        }).join(';');
        onSelectionChange(selectedValuesString);
    }, [cells, onSelectionChange]);

    const columns = [
        {
            title: '',
            dataIndex: 'day',
            key: 'day',
            fixed: 'left' as 'left',
            width: 120,
            render: (text: string) => <div>{text}</div>,
        },
        ...hours.map(hour => ({
            title: `${hour}`,
            dataIndex: `hour-${hour}`,
            key: `hour-${hour}`,
            width: 60,
            render: (_: any, record: any) => {
                const cell = cells.find(row => row[0].day === record.day)?.[hour];
                return (
                    <div
                        className={`cell ${cell?.selected ? 'selected' : ''}`}
                        onMouseDown={() => handleCellClick(record.day, hour)}
                        onMouseOver={() => handleMouseOver(record.day, hour)}
                        style={{ cursor: 'pointer' }}
                    ></div>
                );
            },
        })),
    ];

    const data = daysOfWeek.map(day => {
        const rowData: any = { key: day, day };
        hours.forEach(hour => {
            rowData[`hour-${hour}`] = cells.find(row => row[0].day === day)?.[hour].selected;
        });
        return rowData;
    });

    const selectedValuesString = daysOfWeek.map(day => {
        const dayCells = cells.find(row => row[0].day === day);
        return dayCells?.filter(cell => cell.selected).map(cell => cell.hour).join(',') || '0';
    }).join(';');

    return (
        <div className="ad-scheduler" onMouseDown={handleMouseDown} onMouseUp={handleMouseUp}>
            <Table
                columns={columns}
                dataSource={data}
                pagination={false}
                bordered
                scroll={{ x: true }}
            />
            <div style={{ textAlign: 'right', marginTop: '16px' }}>
                <Button onClick={() => onSave(selectedValuesString)}>Сохранить</Button>
            </div>
        </div>
    );
};

export default AdScheduler;
