import React, { useEffect, useState } from 'react';
import { Card, Col, Row, Spin } from 'antd';
import AdsTable from '../components/AdsTable';
import { Ad } from '../api/apiModels';
import { fetchAds } from '../api/api';

interface AnnouncementsProps {
    selectedChannel: number | null;
}

const Announcements: React.FC<AnnouncementsProps> = ({ selectedChannel }) => {
    const [ads, setAds] = useState<Ad[]>([]);
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        const fetchData = async () => {
            if (selectedChannel !== null) {
                setLoading(true);
                try {
                    const response = await fetchAds(selectedChannel);
                    setAds(response);
                } catch (error) {
                    console.error('Failed to fetch ads', error);
                } finally {
                    setLoading(false);
                }
            } else {
                setLoading(false);
            }
        };
        fetchData();
    }, [selectedChannel]);

    return (
        <Row gutter={16} style={{ marginTop: 16 }}>
            <Col span={24}>
                <Card>
                    {loading ? <Spin /> : <AdsTable ads={ads} />}
                </Card>
            </Col>
        </Row>
    );
};

export default Announcements;
