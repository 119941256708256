import React, { useState, useEffect } from 'react';
import { Layout, Menu, Select } from 'antd';
import {
    MenuUnfoldOutlined,
    MenuFoldOutlined,
    AreaChartOutlined,
    ReconciliationOutlined,
    HomeOutlined,
    LogoutOutlined,
    FormOutlined
} from '@ant-design/icons';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { clearAuthData } from '../../store/authSlice';
import './ChannelLayout.css';
import { clearCache } from "../../store/channelSlice";

const { Header, Sider, Content, Footer } = Layout;
const { Option } = Select;

interface ChannelLayoutProps {
    children: React.ReactNode;
    channels: { id: string; name: string }[];
    selectedChannel: string | null;
    onChannelChange: (channelId: string) => void;
}

const ChannelLayout: React.FC<ChannelLayoutProps> = ({ children, channels, selectedChannel, onChannelChange }) => {
    const [collapsed, setCollapsed] = useState(true);
    const [mobileView, setMobileView] = useState(window.innerWidth < 768);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        const handleResize = () => {
            setMobileView(window.innerWidth < 768);
        };

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    useEffect(() => {
        if (mobileView) {
            setCollapsed(true);
        }
    }, [location]);

    const toggle = () => {
        setCollapsed(!collapsed);
    };

    const handleLogout = () => {
        dispatch(clearAuthData());
        dispatch(clearCache());
        navigate('/login');
    };

    const menuItems = [
        {
            key: '1',
            icon: <HomeOutlined />,
            label: <Link to="/">Главная</Link>,
        },
        {
            key: '2',
            icon: <AreaChartOutlined />,
            label: 'Статистика',
            children: [
                {
                    key: '2-1',
                    label: <Link to="/statistics/finance">Финансы</Link>,
                },
                {
                    key: '2-2',
                    label: <Link to="/statistics/cabinets">Каналы</Link>,
                },
                // {
                //     key: '2-3',
                //     label: <Link to="/statistics/ads">Статистика по объявлениям</Link>,
                // },
            ],
        },
        {
            key: '3',
            icon: <ReconciliationOutlined />,
            label: 'Объявления',
            children: [
                {
                    key: '3-1',
                    label: <Link to="/announcements">Список объявлений</Link>,
                },
                {
                    key: '3-2',
                    label: <Link to="/create-announcement">Создать объявление</Link>,
                    icon: <FormOutlined />
                },
            ],
        },
        {
            key: '4',
            icon: <LogoutOutlined />,
            label: 'Выйти',
            onClick: handleLogout,
        },
    ];

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <Sider
                trigger={null}
                collapsible
                collapsed={collapsed}
                breakpoint="lg"
                collapsedWidth={mobileView ? 0 : 80}
                width={mobileView ? '100vw' : 200}
                style={{ zIndex: 1000, position: 'fixed', left: 0, top: 0, bottom: 0, overflow: 'auto' }}
            >
                <div className="logo" style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: '10px', fontSize: '18px', color: '#fff' }}>
                    {mobileView && <span>Whale Friend Media</span>}
                    {mobileView && (
                        <div onClick={toggle} style={{ cursor: 'pointer' }}>
                            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        </div>
                    )}
                </div>
                <Menu theme="dark" mode="inline" items={menuItems} />
            </Sider>
            <Layout className="site-layout" style={{ marginLeft: mobileView ? 0 : (collapsed ? 80 : 200) }}>
                <Header className="site-layout-background" style={{ padding: 0, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                    <div style={{ display: 'flex', alignItems: 'center', color: '#fff' }}>
                        <div className="trigger" onClick={toggle} style={{ padding: '0 24px', fontSize: '18px', cursor: 'pointer' }}>
                            {collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                        </div>
                        {!mobileView && (
                            <div className="header-title" style={{ marginLeft: '20px', fontSize: '20px', fontWeight: 'bold' }}>Whale Friend Media</div>
                        )}
                    </div>
                    <div style={{ display: 'flex', alignItems: 'center', marginRight: 24 }}>
                        <span style={{ color: '#fff', marginRight: 10 }}>Выберите канал:</span>
                        <Select
                            placeholder="Выберите канал"
                            onChange={onChannelChange}
                            value={selectedChannel}
                            style={{ width: 200 }}
                        >
                            {channels.map(channel => (
                                <Option key={channel.id} value={channel.id} label={<span dangerouslySetInnerHTML={{ __html: channel.name }} />}>
                                    <span dangerouslySetInnerHTML={{ __html: channel.name }} />
                                </Option>
                            ))}
                        </Select>
                    </div>
                </Header>
                <Content style={{ margin: '24px 16px', padding: 24, background: '#fff' }}>
                    {children}
                </Content>
                <Footer style={{ textAlign: 'center' }}>
                    Whale Friend Media ©{new Date().getFullYear()}
                </Footer>
            </Layout>
        </Layout>
    );
};

export default ChannelLayout;
