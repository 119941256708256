import React, { useEffect } from 'react';
import { Row, Col, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';
import { BASE_URL, TELEGRAM_BOT_USERNAME } from '../constants';

const { Title } = Typography;

const LoginPage: React.FC = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Проверка авторизации
        const authData = localStorage.getItem('jwtToken');
        if (authData) {
            navigate('/');
        }

        const script = document.createElement('script');
        script.src = "https://telegram.org/js/telegram-widget.js?7";
        script.setAttribute('data-telegram-login', TELEGRAM_BOT_USERNAME);
        script.setAttribute('data-size', 'large');
        script.setAttribute('data-radius', '10');
        script.setAttribute('data-auth-url', `${BASE_URL}/handleAuth`); // URL для обработки авторизации
        script.setAttribute('data-request-access', 'write');
        document.getElementById('telegram-login-button')?.appendChild(script);
    }, [navigate]);

    return (
        <div style={{ height: '100vh', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <Row justify="center" align="middle" style={{ width: '100%', padding: '0 20px' }}>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <Title level={1}>Whale Friend Media</Title>
                </Col>
                <Col span={24} style={{ textAlign: 'center' }}>
                    <div id="telegram-login-button"></div>
                </Col>
            </Row>
        </div>
    );
};

export default LoginPage;
