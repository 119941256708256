const apiClient = async (url: string, options: RequestInit = {}) => {
    const token = await waitForToken();
    const defaultHeaders = {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
    };

    const response = await fetch(url, {
        ...options,
        headers: {
            ...defaultHeaders,
            ...options.headers,
        },
    });

    if (response.status === 401) {
        // Сессия истекла
        localStorage.removeItem('token');
        window.location.href = '/login'; // Перенаправить пользователя на страницу входа
        return Promise.reject('Unauthorized');
    }

    if (!response.ok) {
        return Promise.reject(response.statusText);
    }

    return response.json();
};

export default apiClient;

const waitForToken = (): Promise<string> => {
    return new Promise((resolve, reject) => {
        const token = localStorage.getItem('token');
        if (token) {
            resolve(token);
        } else {
            const onStorageChange = (e: StorageEvent) => {
                if (e.key === 'token' && e.newValue) {
                    window.removeEventListener('storage', onStorageChange);
                    resolve(e.newValue);
                }
            };
            window.addEventListener('storage', onStorageChange);
        }
    });
};
