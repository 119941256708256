import React, { useState, useEffect } from 'react';
import { Select, Row, Col, Spin } from 'antd';
import { fetchAvailableDates, fetchReplenishments, fetchChannelStatistics } from '../../../api/api';
import { Replenishment, StatisticsData } from '../../../api/apiModels';
import StatisticsTable from './StatisticsTable';
import ReplenishmentTable from './ReplenishmentTable';
import './ChannelStatistics.css';

const { Option } = Select;

interface ChannelStatisticsProps {
    selectedChannel: number | null;
}

const ChannelStatistics: React.FC<ChannelStatisticsProps> = ({ selectedChannel }) => {
    const [availableDates, setAvailableDates] = useState<string[]>([]);
    const [statistics, setStatistics] = useState<StatisticsData[]>([]);
    const [replenishments, setReplenishments] = useState<Replenishment[]>([]);
    const [selectedDate, setSelectedDate] = useState<string | null>(null);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const loadAvailableDates = async () => {
            if (selectedChannel !== null) {
                try {
                    setLoading(true);
                    const dates = await fetchAvailableDates(selectedChannel);
                    setAvailableDates(dates);
                    if (dates.length > 0) {
                        setSelectedDate(dates[0]);
                    }
                } catch (error) {
                    console.error('Failed to fetch available dates', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        loadAvailableDates();
    }, [selectedChannel]);

    useEffect(() => {
        const loadStatisticsAndReplenishments = async () => {
            if (selectedChannel !== null && selectedDate !== null) {
                try {
                    setLoading(true);
                    const [year, month] = selectedDate.split('-');
                    const stats = await fetchChannelStatistics(selectedChannel, parseInt(year), parseInt(month));
                    const repls = await fetchReplenishments(selectedChannel);
                    setStatistics(stats);
                    setReplenishments(repls);
                } catch (error) {
                    console.error('Failed to fetch data', error);
                } finally {
                    setLoading(false);
                }
            }
        };

        loadStatisticsAndReplenishments();
    }, [selectedChannel, selectedDate]);

    const handleDateChange = (date: string) => {
        setSelectedDate(date);
    };

    if (loading) {
        return <Spin />;
    }

    return (
        <div>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Select
                        style={{ width: '100%' }}
                        placeholder="Выберите год и месяц"
                        onChange={handleDateChange}
                        value={selectedDate}
                    >
                        {availableDates.map((date) => (
                            <Option key={date} value={date}>
                                {date}
                            </Option>
                        ))}
                    </Select>
                </Col>
            </Row>
            <Row gutter={[16, 16]}>
                <Col xs={24} md={12}>
                    <StatisticsTable statistics={statistics} />
                </Col>
                <Col xs={24} md={12}>
                    <ReplenishmentTable replenishments={replenishments} />
                </Col>
            </Row>
        </div>
    );
};

export default ChannelStatistics;
