import React from 'react';
import { Table } from 'antd';
import { StatisticsData } from '../../../api/apiModels';

interface StatisticsTableProps {
    statistics: StatisticsData[];
}

const StatisticsTable: React.FC<StatisticsTableProps> = ({ statistics }) => {
    const totalSpentTon = statistics.reduce((acc, stat) => acc + stat.spentTon, 0);
    const totalSubscribers = statistics.reduce((acc, stat) => acc + stat.subscribers, 0);
    const averageCostPerSubscriber = totalSubscribers ? totalSpentTon / totalSubscribers : 0;

    return (
        <Table
            dataSource={statistics.map((item, index) => ({ ...item, key: index }))}
            columns={[
                { title: 'Дата', dataIndex: 'date', key: 'date' },
                { title: 'Потрачено 💎', dataIndex: 'spentTon', key: 'spentTon' },
                { title: 'Подписчики', dataIndex: 'subscribers', key: 'subscribers' },
                { title: 'Цена подписчика', dataIndex: 'costPerSubscriber', key: 'costPerSubscriber' },
            ]}
            summary={() => (
                <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Итого</Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>{totalSpentTon.toFixed(5)}</Table.Summary.Cell>
                    <Table.Summary.Cell index={2}>{totalSubscribers}</Table.Summary.Cell>
                    <Table.Summary.Cell index={3}>{averageCostPerSubscriber.toFixed(5)}</Table.Summary.Cell>
                </Table.Summary.Row>
            )}
            style={{ width: '100%' }}
        />
    );
};

export default StatisticsTable;
