import apiClient from './apiClient';
import {BACKEND_API_URL} from "../constants";
import {
    Ad, BalanceResponse, Channel, ChannelBalancesResponse,
    CreateAnnouncementRequest,
    CreateAnnouncementResponse,
    DailyStatisticsData, Replenishment, StatisticsData, StatisticsResponse,
    ValidateChannelResponse,
    CabinetStatisticsRequest,
    CabinetStatisticsResponse
} from "./apiModels";
export const validateChannel = async (url: string): Promise<ValidateChannelResponse> => {
    return apiClient(`${BACKEND_API_URL}/validate-channel`, {
        method: 'POST',
        body: JSON.stringify({ url })
    });
};

export const fetchCabinetStatistics = async (data: CabinetStatisticsRequest): Promise<CabinetStatisticsResponse> => {
    return apiClient(`${BACKEND_API_URL}/channels/statistics`, {
        method: 'POST',
        body: JSON.stringify(data)
    });
};


export const validateChannelsBulk = async (urls: string[]): Promise<ValidateChannelResponse[]> => {
    return apiClient(`${BACKEND_API_URL}/validate-channels-bulk`, {
        method: 'POST',
        body: JSON.stringify({ urls }),
    });
};

export const createAnnouncement = async (data: CreateAnnouncementRequest): Promise<CreateAnnouncementResponse> => {
    return apiClient(`${BACKEND_API_URL}/create-announcement`, {
        method: 'POST',
        body: JSON.stringify(data)
    });
};

export const fetchAds = async (channelId: number): Promise<Ad[]> => {
    return apiClient(`${BACKEND_API_URL}/ads?channelId=${channelId}`);
};

export const fetchDailyStatistics = async (timePeriod: string): Promise<DailyStatisticsData[]> => {
    return apiClient(`${BACKEND_API_URL}/channel/day?period=${timePeriod}`);
};

export const fetchBalance = async (): Promise<BalanceResponse> => {
    return apiClient(`${BACKEND_API_URL}/balance`);
};

export const fetchStatistics = async (timePeriod: string): Promise<StatisticsResponse> => {
    return apiClient(`${BACKEND_API_URL}/channel/metrics?period=${timePeriod}`);
};

export const fetchChannelBalances = async (): Promise<ChannelBalancesResponse> => {
    return apiClient(`${BACKEND_API_URL}/channel-balances`);
};

export const fetchChannels = async (): Promise<Channel[]> => {
    return apiClient(`${BACKEND_API_URL}/Channels/List`);
};

export const fetchReplenishments = async (channelId: number): Promise<Replenishment[]> => {
    return apiClient(`${BACKEND_API_URL}/Channels/Replenishments?channelId=${channelId}`);
};

export const fetchAvailableDates = async (channelId: number): Promise<string[]> => {
    return apiClient(`${BACKEND_API_URL}/Channels/AvailableDates?channelId=${channelId}`);
};

export const fetchChannelStatistics = async (channelId: number, year: number, month: number): Promise<StatisticsData[]> => {
    return apiClient(`${BACKEND_API_URL}/Channels/statistics?channelId=${channelId}&year=${year}&month=${month}`);
};
