import React, { useState, useEffect } from 'react';
import { Card, Spin, Select, Row, Col } from 'antd';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { fetchDailyStatistics } from '../api/api';
import { DailyStatisticsData } from '../api/apiModels';

const { Option } = Select;

interface DayStatisticsChartProps {
    timePeriod: string;
}

const metricsMap = {
    spent: 'Потрачено',
    impressions: 'Просмотры',
    subscribers: 'Подписки',
    cps: 'CPS',
    cpm: 'CPM'
} as const;

type MetricKey = keyof typeof metricsMap;

const DayStatisticsChart: React.FC<DayStatisticsChartProps> = ({ timePeriod }) => {
    const [data, setData] = useState<DailyStatisticsData[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [selectedMetrics, setSelectedMetrics] = useState<MetricKey[]>(Object.keys(metricsMap) as MetricKey[]);

    useEffect(() => {
        const fetchData = async () => {
            setLoading(true);
            try {
                const response = await fetchDailyStatistics(timePeriod);
                setData(response);
            } catch (error) {
                console.error('Failed to fetch daily statistics', error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
    }, [timePeriod]);

    const handleMetricsChange = (value: MetricKey[]) => {
        setSelectedMetrics(value);
    };

    const formattedData = data.map(item => {
        const formattedItem: any = { day: item.day };
        selectedMetrics.forEach(metric => {
            formattedItem[metric] = item[metric];
        });
        return formattedItem;
    });

    console.log(formattedData);

    return (
        <Card style={{ marginTop: 16 }}>
            <Row gutter={[16, 16]}>
                <Col span={24}>
                    <Select
                        mode="multiple"
                        defaultValue={Object.keys(metricsMap) as MetricKey[]}
                        onChange={handleMetricsChange}
                        style={{ width: '100%' }}
                    >
                        {Object.keys(metricsMap).map((key) => (
                            <Option key={key} value={key as MetricKey}>
                                {metricsMap[key as MetricKey]}
                            </Option>
                        ))}
                    </Select>
                </Col>
                <Col span={24}>
                    {loading ? <Spin /> : (
                        <ResponsiveContainer width="100%" height={400}>
                            <LineChart data={formattedData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                                <CartesianGrid strokeDasharray="3 3" />
                                <XAxis dataKey="day" />
                                <YAxis />
                                <Tooltip formatter={(value: number) => value.toFixed(2)} />
                                <Legend />
                                {selectedMetrics.map((metric, index) => (
                                    <Line
                                        key={metric}
                                        type="monotone"
                                        dataKey={metric}
                                        name={metricsMap[metric]}
                                        stroke={['#8884d8', '#82ca9d', '#ffc658', '#ff7300', '#387908'][index % 5]}
                                    />
                                ))}
                            </LineChart>
                        </ResponsiveContainer>
                    )}
                </Col>
            </Row>
        </Card>
    );
};

export default DayStatisticsChart;
