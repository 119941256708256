import React, { useState, useEffect } from 'react';
import { Card, Row, Col, Statistic, Select, Modal, Table, message } from 'antd';
import {
    DollarOutlined,
    LineChartOutlined,
    UserAddOutlined,
    BarChartOutlined
} from '@ant-design/icons';
import { fetchBalance, fetchStatistics, fetchChannelBalances } from '../api/api';
import { BalanceResponse, StatisticsResponse, ChannelBalance } from '../api/apiModels';
import './Home.css';
import DailyStatisticsChart from "../components/DayStatisticsChart";

const { Option } = Select;

const Home: React.FC = () => {
    const [balance, setBalance] = useState<BalanceResponse | null>(null);
    const [statistics, setStatistics] = useState<StatisticsResponse | null>(null);
    const [channelBalances, setChannelBalances] = useState<ChannelBalance[]>([]);
    const [timePeriod, setTimePeriod] = useState<string>('week');
    const [modalVisible, setModalVisible] = useState<boolean>(false);

    useEffect(() => {
        const fetchData = async () => {
            try {
                const balanceData = await fetchBalance();
                setBalance(balanceData);
            } catch (error) {
                message.error('Ошибка при получении баланса');
            }
            try {
                const statsData = await fetchStatistics(timePeriod);
                setStatistics(statsData);
            } catch (error) {
                message.error('Ошибка при получении статистики');
            }
            try {
                const channelBalancesData = await fetchChannelBalances();
                setChannelBalances(channelBalancesData.channelBalances);
            } catch (error) {
                message.error('Ошибка при получении балансов каналов');
            }
        };

        fetchData();
    }, [timePeriod]);

    const handleTimePeriodChange = (value: string) => {
        setTimePeriod(value);
    };

    const handleBalanceClick = () => {
        setModalVisible(true);
    };

    const handleModalClose = () => {
        setModalVisible(false);
    };

    const columns = [
        {
            title: 'Канал',
            dataIndex: 'name',
            key: 'name',
            render: (text: string) => <div dangerouslySetInnerHTML={{ __html: text }} />
        },
        {
            title: 'Баланс (💎)',
            dataIndex: 'balanceChannel',
            key: 'balanceChannel',
            sorter: (a: ChannelBalance, b: ChannelBalance) => a.balanceChannel - b.balanceChannel,
        },
        {
            title: 'Объявления (💎)',
            dataIndex: 'balanceAd',
            key: 'balanceAd',
            sorter: (a: ChannelBalance, b: ChannelBalance) => a.balanceAd - b.balanceAd,
        },
        {
            title: 'Всего (💎)',
            dataIndex: 'balanceAll',
            key: 'balanceAll',
            sorter: (a: ChannelBalance, b: ChannelBalance) => a.balanceAll - b.balanceAll,
            defaultSortOrder: 'descend' as 'descend', // Устанавливаем сортировку по умолчанию
        },
    ];

    return (
        <div className="home-container">
            <Card className="welcome-card">
                <h1>Добро пожаловать в Whale Friend Media</h1>
                <p>Мы предоставляем статистику по каналам, статистику по объявлениям и управление объявлениями.</p>
            </Card>
            <Row gutter={16} style={{ marginTop: 16 }}>
                <Col span={24}>
                    <Card onClick={handleBalanceClick}>
                        <Statistic
                            title="Общий баланс"
                            value={balance?.totalBalance}
                            prefix={<DollarOutlined />}
                            suffix="💎"
                            style={{ cursor: 'pointer' }}
                        />
                        <Row gutter={16} style={{ marginTop: 16 }}>
                            <Col span={12}>
                                <Statistic title="Баланс к размещению" value={balance?.balanceToAllocate} prefix={<DollarOutlined />} suffix="💎" />
                            </Col>
                            <Col span={12}>
                                <Statistic title="Баланс в рекламных кампаниях" value={balance?.balanceInCampaigns} prefix={<DollarOutlined />} suffix="💎" />
                            </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
            <Card style={{ marginTop: 16 }}>
                <Row justify="space-between" align="middle">
                    <Col>
                        <h2>Статистика</h2>
                    </Col>
                    <Col>
                        <Select defaultValue="week" onChange={handleTimePeriodChange} style={{ width: 120 }}>
                            <Option value="today">Сегодня</Option>
                            <Option value="yesterday">Вчера</Option>
                            <Option value="week">7 дней</Option>
                            <Option value="month">30 дней</Option>
                            <Option value="allTime">Все время</Option>
                        </Select>
                    </Col>
                </Row>
                <Row gutter={16} style={{ marginTop: 16 }} justify="center">
                    <Col span={4}>
                        <Statistic title="Расход" value={statistics?.spent} prefix={<DollarOutlined />} suffix="💎" />
                    </Col>
                    <Col span={4}>
                        <Statistic title="Количество показов" value={statistics?.impressions} prefix={<LineChartOutlined />} />
                    </Col>
                    <Col span={4}>
                        <Statistic title="Подписчики" value={statistics?.subscribers} prefix={<UserAddOutlined />} />
                    </Col>
                    <Col span={4}>
                        <Statistic title="CPS" value={statistics?.cps} suffix="💎" />
                    </Col>
                    <Col span={4}>
                        <Statistic title="CPM" value={statistics?.cpm} prefix={<BarChartOutlined />} suffix="💎" />
                    </Col>
                </Row>
            </Card>
            <Modal
                title="Детализация баланса"
                visible={modalVisible}
                onCancel={handleModalClose}
                footer={null}
                width={800} // Устанавливаем ширину модального окна
            >
                <Table
                    dataSource={channelBalances}
                    columns={columns}
                    rowKey="channelId"
                    pagination={false}
                />
            </Modal>
            <DailyStatisticsChart timePeriod={timePeriod} />
        </div>
    );
};

export default Home;
