import { createSlice, createAsyncThunk, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from './index';
import { fetchChannels as fetchChannelsApi } from '../api/api';
import { Channel } from '../api/apiModels';

interface ChannelsState {
    channels: Channel[];
    selectedChannel: number | null;
}

const initialState: ChannelsState = {
    channels: [],
    selectedChannel: null,
};

export const fetchChannelsAsync = createAsyncThunk<Channel[], void>(
    'channels/fetchChannels',
    async (): Promise<Channel[]> => {
        const response = await fetchChannelsApi();
        return response;
    }
);

const channelSlice = createSlice({
    name: 'channels',
    initialState,
    reducers: {
        setSelectedChannel: (state, action: PayloadAction<number>) => {
            state.selectedChannel = action.payload;
            localStorage.setItem('selectedChannelId', action.payload.toString());
        },
        setChannels: (state, action: PayloadAction<Channel[]>) => {
            state.channels = action.payload;
            localStorage.setItem('channels', JSON.stringify(action.payload));
        },
        clearCache: (state) => {
            state.channels = [];
            state.selectedChannel = null;
            localStorage.removeItem('channels');
            localStorage.removeItem('selectedChannelId');
        },
    },
    extraReducers: (builder) => {
        builder.addCase(fetchChannelsAsync.fulfilled, (state, action: PayloadAction<Channel[]>) => {
            state.channels = action.payload;
            localStorage.setItem('channels', JSON.stringify(action.payload));
            const savedChannelId = localStorage.getItem('selectedChannelId');
            if (savedChannelId) {
                const channelExists = action.payload.some(channel => channel.id === Number(savedChannelId));
                if (channelExists) {
                    state.selectedChannel = Number(savedChannelId);
                } else if (action.payload.length > 0) {
                    state.selectedChannel = action.payload[0].id;
                    localStorage.setItem('selectedChannelId', action.payload[0].id.toString());
                }
            } else if (action.payload.length > 0) {
                state.selectedChannel = action.payload[0].id;
                localStorage.setItem('selectedChannelId', action.payload[0].id.toString());
            }
        });
        builder.addCase(fetchChannelsAsync.rejected, (state, action) => {
            console.error(action.error.message);
        });
    },
});

export const { setSelectedChannel, setChannels, clearCache } = channelSlice.actions;
export const selectChannels = (state: RootState): Channel[] => state.channels.channels;
export const selectSelectedChannel = (state: RootState): number | null => state.channels.selectedChannel;
export default channelSlice.reducer;
