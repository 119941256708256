import React, { useState } from 'react';
import { Form, Input, Button, Checkbox, Modal, InputNumber, Select, Typography, List, Spin, message, Row, Col } from 'antd';
import { validateChannel, validateChannelsBulk, createAnnouncement } from '../api/api';
import { CreateAnnouncementRequest, ValidateChannelResponse } from '../api/apiModels';
import AdScheduler from "../components/AdScheduler";

const { TextArea } = Input;
const { Option } = Select;
const { Title } = Typography;

const daysOfWeek = ['Понедельник', 'Вторник', 'Среда', 'Четверг', 'Пятница', 'Суббота', 'Воскресенье'];

interface ChannelInfo {
    id: string;
    name: string;
    photo: string;
}

interface CreateAnnouncementProps {
    selectedChannelId: number | null;
}

const CreateAnnouncement: React.FC<CreateAnnouncementProps> = ({ selectedChannelId }) => {
    const [form] = Form.useForm();
    const [channelModalVisible, setChannelModalVisible] = useState(false);
    const [scheduleModalVisible, setScheduleModalVisible] = useState(false);
    const [channels, setChannels] = useState<ChannelInfo[]>([]);
    const [channelError, setChannelError] = useState<{ [key: string]: string }>({});
    const [loading, setLoading] = useState(false);
    const [bulkLoading, setBulkLoading] = useState(false);
    const [bulkProgress, setBulkProgress] = useState({ processed: 0, total: 0, errors: 0 });
    const [scheduleEnabled, setScheduleEnabled] = useState(false);
    const [scheduleString, setScheduleString] = useState('');
    const [utcOffset, setUtcOffset] = useState<number>(0);
    const [scheduleDisplay, setScheduleDisplay] = useState<string>('');

    const handleAddChannel = async (url: string) => {
        if (!url || channels.some(channel => channel.name === url)) {
            setChannelError({ [url]: 'Канал уже добавлен или пустой' });
            message.error('Канал уже добавлен или пустой');
            return;
        }
        setLoading(true);
        try {
            const validation: ValidateChannelResponse = await validateChannel(url);
            if (validation.success && validation.channelId) {
                setChannels([...channels, { id: validation.channelId, name: url, photo: validation.photo }]);
                setChannelError({});
                message.success('Канал успешно добавлен');
            } else {
                setChannelError({ [url]: validation.message ?? 'Ошибка валидации' });
                message.error(validation.message ?? 'Ошибка валидации');
            }
        } catch (error) {
            message.error('Ошибка при валидации канала');
        } finally {
            setLoading(false);
        }
    };

    const handleBulkAddChannels = async (bulkUrls: string) => {
        const urls = bulkUrls.split('\n').map(url => url.trim()).filter(url => url && !channels.some(channel => channel.name === url));
        if (urls.length === 0) return;

        setBulkLoading(true);
        setBulkProgress({ processed: 0, total: urls.length, errors: 0 });

        const chunks = [];
        for (let i = 0; i < urls.length; i += 100) {
            chunks.push(urls.slice(i, i + 100));
        }

        const newChannels: ChannelInfo[] = [];
        const newErrors: { [key: string]: string } = {};

        for (const chunk of chunks) {
            try {
                const validationResults = await validateChannelsBulk(chunk);
                validationResults.forEach(validation => {
                    if (validation.success && validation.channelId) {
                        newChannels.push({ id: validation.channelId, name: validation.url, photo: validation.photo });
                    } else if (validation.url) {
                        newErrors[validation.url] = validation.message ?? 'Ошибка валидации';
                    }
                });
                setBulkProgress(prev => ({
                    processed: prev.processed + chunk.length,
                    total: urls.length,
                    errors: prev.errors + validationResults.filter(v => !v.success).length,
                }));
            } catch (error) {
                message.error('Ошибка при массовой валидации каналов');
            }
        }

        setChannels([...channels, ...newChannels]);
        setChannelError({ ...channelError, ...newErrors });
        setBulkLoading(false);
        message.success('Массовая валидация завершена');
    };

    const handleRemoveChannel = (id: string) => {
        setChannels(channels.filter(channel => channel.id !== id));
        message.info('Канал удален из списка');
    };

    const handleSubmit = async (values: CreateAnnouncementRequest) => {
        if (!selectedChannelId) {
            console.error('Не выбран канал');
            message.error('Не выбран канал');
            return;
        }
        if (channels.length === 0) {
            console.error('Не добавлен ни один канал');
            message.error('Не добавлен ни один канал');
            return;
        }
        setLoading(true);
        try {
            const data: CreateAnnouncementRequest = {
                ...values,
                channels: channels.map(channel => channel.id),
                status: values.status || false,
                channelId: selectedChannelId,
                schedule: scheduleString, // Добавляем расписание в данные формы
                utcOffset, // Добавляем UTC Offset
            };

            const response = await createAnnouncement(data);
            if (response.success) {
                form.resetFields();
                setChannels([]);
                setChannelError({});
                message.success('Объявление успешно создано');
            } else {
                console.error('Ошибка отправки данных', response.message);
                message.error(`Ошибка отправки данных: ${response.message}`);
            }
        } catch (error) {
            console.error('Ошибка отправки данных', error);
            message.error('Ошибка отправки данных');
        } finally {
            setLoading(false);
        }
    };

    const handleScheduleSave = (schedule: string) => {
        console.log(schedule);
        setScheduleString(schedule);
        setScheduleModalVisible(false);
    };

    const handleScheduleCancel = () => {
        setScheduleModalVisible(false);
    };

    const handleScheduleChange = (schedule: string) => {
        setScheduleDisplay(schedule);
        console.log(schedule);
    };

    const renderSchedule = (schedule: string) => {
        const days = schedule.split(';');
        return days.map((daySchedule, index) => {
            const dayName = daysOfWeek[index];
            const selectedHours: string[] = [];
            let rangeStart: number | null = null;

            if (!daySchedule || daySchedule === '0') {
                return null;
            }

            daySchedule.split(',').forEach((hour, i, arr) => {
                const hourNum = parseInt(hour, 10);
                if (rangeStart === null) {
                    rangeStart = hourNum;
                } else if (hourNum !== parseInt(arr[i - 1], 10) + 1) {
                    selectedHours.push(rangeStart === parseInt(arr[i - 1], 10) ? `${rangeStart}` : `${rangeStart}-${parseInt(arr[i - 1], 10)}`);
                    rangeStart = hourNum;
                }
                if (i === arr.length - 1) {
                    selectedHours.push(rangeStart === hourNum ? `${hourNum}` : `${rangeStart}-${hourNum}`);
                }
            });

            return (
                <p key={index}>
                    {dayName}: {selectedHours.join(', ')}
                </p>
            );
        }).filter(Boolean);
    };

    return (
        <div className="create-announcement-container">
            {selectedChannelId ? (
                <div className="create-announcement-form">
                    <Title level={2} style={{ textAlign: 'center' }}>Создание объявления</Title>
                    <Form
                        form={form}
                        layout="vertical"
                        onFinish={handleSubmit}
                        initialValues={{ dailyImpressions: 1, budget: 0, cpm: 0.10 }}
                    >
                        <Row gutter={16}>
                            <Col span={12}>
                                <Form.Item
                                    label="Название"
                                    name="title"
                                    rules={[{ required: true, message: 'Пожалуйста, введите название' }]}
                                >
                                    <Input placeholder="Введите название объявления" />
                                </Form.Item>
                                <Form.Item
                                    label="Текст"
                                    name="text"
                                    rules={[
                                        { required: true, message: 'Пожалуйста, введите текст' },
                                        { max: 160, message: 'Текст не может быть длиннее 160 символов' }
                                    ]}
                                >
                                    <TextArea rows={2} placeholder="Введите текст объявления (максимум 160 символов)" />
                                </Form.Item>
                                <Form.Item
                                    label="Ссылка на канал"
                                    name="link"
                                    rules={[
                                        { required: true, message: 'Пожалуйста, введите ссылку на канал' },
                                        { type: 'url', message: 'Пожалуйста, введите правильный URL' }
                                    ]}
                                >
                                    <Input placeholder="Введите ссылку на канал" />
                                </Form.Item>
                                <Form.Item
                                    label="Каналы"
                                >
                                    <Input.Search
                                        placeholder="Введите URL канала"
                                        enterButton="Добавить"
                                        onSearch={handleAddChannel}
                                        disabled={loading}
                                    />
                                    <Button type="link" onClick={() => setChannelModalVisible(true)} disabled={loading}>Добавить много каналов</Button>
                                    {loading && <Spin />}
                                    <List
                                        bordered
                                        dataSource={channels}
                                        renderItem={channel => (
                                            <List.Item
                                                actions={[<Button type="link" onClick={() => handleRemoveChannel(channel.id)}>Удалить</Button>]}
                                            >
                                                <List.Item.Meta
                                                    avatar={<img src={channel.photo} alt={channel.name} style={{ width: 25, height: 25 }} />}
                                                    title={channel.name}
                                                />
                                            </List.Item>
                                        )}
                                        style={{ maxHeight: 200, overflowY: 'scroll', marginTop: 10 }}
                                    />
                                    {Object.keys(channelError).length > 0 && (
                                        <div style={{ maxHeight: 100, overflowY: 'scroll', border: '1px solid red', padding: '10px', marginTop: '10px' }}>
                                            {Object.keys(channelError).map(url => (
                                                <div key={url} style={{ color: 'red', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                                                    {url}: {channelError[url]}
                                                </div>
                                            ))}
                                        </div>
                                    )}
                                    <div style={{ marginTop: 10 }}>
                                        <p>Добавлено каналов: {channels.length}</p>
                                        <p>Ошибок: {Object.keys(channelError).length}</p>
                                    </div>
                                </Form.Item>
                            </Col>
                            <Col span={12}>
                                <Form.Item
                                    label="CPM"
                                    name="cpm"
                                    rules={[{ required: true, min: 0.10, type: 'number', message: 'CPM должно быть не менее 0.10 💎' }]}
                                >
                                    <InputNumber min={0.10} step={0.01} placeholder="Введите CPM" style={{ width: '100%' }} />
                                </Form.Item>
                                <Form.Item
                                    label="Количество показов в день"
                                    name="dailyImpressions"
                                    rules={[{ required: true, message: 'Пожалуйста, выберите количество показов в день' }]}
                                >
                                    <Select defaultValue={1} placeholder="Выберите количество показов в день" style={{ width: '100%' }}>
                                        <Option value={1}>1</Option>
                                        <Option value={2}>2</Option>
                                        <Option value={3}>3</Option>
                                        <Option value={4}>4</Option>
                                    </Select>
                                </Form.Item>
                                <Form.Item
                                    label="Бюджет"
                                    name="budget"
                                    rules={[{ required: true, type: 'number', min: 0, message: 'Бюджет должен быть больше 0' }]}
                                >
                                    <InputNumber min={0} step={0.01} placeholder="Введите бюджет" style={{ width: '100%' }} />
                                </Form.Item>
                                <Form.Item
                                    label="Дневной лимит"
                                    name="dailyLimit"
                                >
                                    <InputNumber min={0} step={0.01} placeholder="Введите дневной лимит" style={{ width: '100%' }} />
                                </Form.Item>
                                <Form.Item name="status" valuePropName="checked">
                                    <Checkbox>Активный</Checkbox>
                                </Form.Item>
                                <Form.Item name="scheduleEnabled" valuePropName="checked">
                                    <Checkbox onChange={(e) => setScheduleEnabled(e.target.checked)}>Включить расписание</Checkbox>

                                </Form.Item>
                                <Form.Item>
                                    <Button type="primary" onClick={() => setScheduleModalVisible(true)} disabled={!scheduleEnabled}>Расписание</Button>
                                </Form.Item>
                                {scheduleDisplay && (
                                    <Form.Item label="Выбранное расписание">
                                        <div style={{
                                            maxHeight: '200px',
                                            overflowY: 'scroll'
                                        }}>{renderSchedule(scheduleDisplay)}</div>
                                        <p>Выбранная тайм-зона: UTC {utcOffset >= 0 ? `+${utcOffset}` : utcOffset}</p>
                                    </Form.Item>
                                )}
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col>
                                <Form.Item>
                                    <Button type="default" onClick={() => form.resetFields()} disabled={loading || bulkLoading}>Очистить</Button>
                                    <Button type="primary" htmlType="submit" style={{ marginLeft: '10px' }} disabled={loading || bulkLoading}>Отправить</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                    <Modal
                        visible={channelModalVisible}
                        title="Добавить много каналов"
                        onCancel={() => setChannelModalVisible(false)}
                        footer={null}
                    >
                        <Form onFinish={({ bulkUrls }) => handleBulkAddChannels(bulkUrls)}>
                            <Form.Item name="bulkUrls">
                                <TextArea rows={10} placeholder="Вставьте ссылки на каналы, по одной на строку" />
                            </Form.Item>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" disabled={bulkLoading}>Добавить</Button>
                                {bulkLoading && <Spin style={{ marginLeft: 10 }} />}
                            </Form.Item>
                        </Form>
                        {bulkLoading && (
                            <div style={{ marginTop: 10 }}>
                                <p>Обработано: {bulkProgress.processed}/{bulkProgress.total}</p>
                                <p>Успешно: {channels.length}</p>
                                <p>Ошибки: {bulkProgress.errors}</p>
                            </div>
                        )}
                    </Modal>
                    <Modal
                        visible={scheduleModalVisible}
                        title="Расписание"
                        onCancel={handleScheduleCancel}
                        footer={null} // Убираем кнопки сохранения и отмены из футера
                        width={800} // Устанавливаем ширину модального окна
                    >
                        <Select defaultValue={0} onChange={setUtcOffset} style={{ marginBottom: '16px', width: '100%' }}>
                            {Array.from({ length: 25 }, (_, i) => i - 12).map(offset => (
                                <Option key={offset} value={offset}>
                                    UTC {offset >= 0 ? `+${offset}` : offset}
                                </Option>
                            ))}
                        </Select>
                        <AdScheduler onSave={handleScheduleSave} onSelectionChange={handleScheduleChange} />
                    </Modal>
                </div>
            ) : (
                <div style={{ textAlign: 'center', padding: '50px' }}>
                    <Title level={3}>Пожалуйста, выберите канал</Title>
                </div>
            )}
        </div>
    );
};

export default CreateAnnouncement;
