import React from 'react';
import { Table } from 'antd';
import { Replenishment } from '../../../api/apiModels';

interface ReplenishmentTableProps {
    replenishments: Replenishment[];
}

const ReplenishmentTable: React.FC<ReplenishmentTableProps> = ({ replenishments }) => {
    const totalAmount = replenishments.reduce((acc, repl) => acc + repl.amount, 0);
    const totalServices = replenishments.reduce((acc, repl) => acc + repl.services, 0);

    return (
        <Table
            dataSource={replenishments.map((item, index) => ({ ...item, key: index }))}
            columns={[
                { title: 'Дата', dataIndex: 'date', key: 'date' },
                { title: 'Сумма (💎)', dataIndex: 'amount', key: 'amount' },
                //{ title: 'Услуги (💎)', dataIndex: 'services', key: 'services' },
            ]}
            summary={() => (
                <Table.Summary.Row>
                    <Table.Summary.Cell index={0}>Итого</Table.Summary.Cell>
                    <Table.Summary.Cell index={1}>{totalAmount.toFixed(2)}</Table.Summary.Cell>
                   {/*/<Table.Summary.Cell index={2}>{totalServices.toFixed(2)}</Table.Summary.Cell>*/}
                </Table.Summary.Row>
            )}
            style={{ width: '100%' }}
        />
    );
};

export default ReplenishmentTable;
