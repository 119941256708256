import React, { useState } from 'react';
import { Table, Select, Row, Col, Button, Tooltip } from 'antd';
import moment from 'moment';
import { Ad } from '../api/apiModels';

const { Option } = Select;

interface AdsTableProps {
    ads: Ad[];
}

const AdsTable: React.FC<AdsTableProps> = ({ ads }) => {
    const statusMap: { [key: number]: string } = {
        0: 'none',
        1: 'Активный',
        2: 'Заморожен',
        3: 'Остановлен',
        4: 'На проверке',
    };

    const allColumns = [
        { title: 'Id', dataIndex: 'id', key: 'AdId', sorter: (a: Ad, b: Ad) => a.id - b.id },
        { title: 'Название', dataIndex: 'title', key: 'Title' },
        { title: 'Просмотры', dataIndex: 'views', key: 'Views' },
        { title: 'Подписки', dataIndex: 'joins', key: 'Joins' },
        { title: 'Клики', dataIndex: 'clicks', key: 'Clicks' },
        {
            title: 'Дата Создания',
            dataIndex: 'dateAdd',
            key: 'DateAdd',
            render: (text: string) => moment.utc(text).local().format('YYYY-MM-DD HH:mm:ss'),
            sorter: (a: Ad, b: Ad) => moment(a.dateAdd).diff(moment(b.dateAdd)),
        },
        {
            title: (
                <Tooltip title="Click-Through Rate: соотношение кликов к показам">
                    CTR (%)
                </Tooltip>
            ),
            dataIndex: 'ctr',
            key: 'CTR',
            render: (value: number) => `${value.toFixed(2)}%`,
            sorter: (a: Ad, b: Ad) => a.ctr - b.ctr
        },
        {
            title: (
                <Tooltip title="Cost Per Mille: стоимость за тысячу показов">
                    CPM (💎)
                </Tooltip>
            ),
            dataIndex: 'cpm',
            key: 'CPM',
            render: (value: number) => `${value.toFixed(4)} 💎`,
            sorter: (a: Ad, b: Ad) => a.cpm - b.cpm
        },
        {
            title: (
                <Tooltip title="Cost Per Click: стоимость за клик">
                    CPC (💎)
                </Tooltip>
            ),
            dataIndex: 'cpc',
            key: 'CPC',
            render: (value: number) => `${value.toFixed(4)} 💎`,
            sorter: (a: Ad, b: Ad) => a.cpc - b.cpc
        },
        {
            title: (
                <Tooltip title="Cost Per Subscriber: стоимость за подписчика">
                    CPS (💎)
                </Tooltip>
            ),
            dataIndex: 'cps',
            key: 'CPS',
            render: (value: number) => `${value.toFixed(4)} 💎`,
            sorter: (a: Ad, b: Ad) => a.cps - b.cps
        },
        { title: 'Бюджет', dataIndex: 'budget', key: 'Budget', sorter: (a: Ad, b: Ad) => a.budget - b.budget },
        { title: 'Потрачено', dataIndex: 'spent', key: 'Spent', sorter: (a: Ad, b: Ad) => a.spent - b.spent },
        {
            title: 'Статус',
            dataIndex: 'status',
            key: 'Status',
            render: (status: number) => statusMap[status],
            sorter: (a: Ad, b: Ad) => a.status - b.status,
        },
    ];

    const [selectedColumns, setSelectedColumns] = useState(allColumns.map(col => col.key));

    const handleColumnChange = (selectedKeys: string[]) => {
        setSelectedColumns(selectedKeys);
    };

    const filteredColumns = allColumns.filter(col => selectedColumns.includes(col.key));

    return (
        <>
            <Row style={{ marginBottom: 16 }}>
                <Col span={24}>
                    <Select
                        mode="multiple"
                        style={{ width: '100%' }}
                        placeholder="Выберите столбцы"
                        value={selectedColumns}
                        onChange={handleColumnChange}
                    >
                        {allColumns.map(col => (
                            <Option key={col.key} value={col.key}>
                                {col.title}
                            </Option>
                        ))}
                    </Select>
                </Col>
                <Col span={24} style={{ textAlign: 'right', marginTop: 8 }}>
                    <Button type="primary" onClick={() => setSelectedColumns(allColumns.map(col => col.key))}>
                        Select All
                    </Button>
                    <Button style={{ marginLeft: 8 }} onClick={() => setSelectedColumns([])}>
                        Deselect All
                    </Button>
                </Col>
            </Row>
            <Table
                dataSource={ads}
                columns={filteredColumns}
                rowKey="id"
                expandable={{
                    expandedRowRender: record => <div dangerouslySetInnerHTML={{ __html: record.text }} />,
                    rowExpandable: record => !!record.text && record.text.length > 0,
                }}
                pagination={false}
                scroll={{ x: 'max-content', y: 500 }} // Установка вертикальной прокрутки
                style={{ maxHeight: '80vh', overflow: 'auto' }} // Ограничение высоты таблицы и добавление вертикальной прокрутки
            />
        </>
    );
};

export default AdsTable;
