import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { setAuthData } from '../store/authSlice';
import {BACKEND_API_URL} from "../constants";

const HandleAuth: React.FC = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();

    useEffect(() => {
        const handleAuth = async () => {
            const params = new URLSearchParams(window.location.search);
            const id = params.get('id');
            const authDate = params.get('auth_date');
            const hash = params.get('hash');
            const firstName = params.get('first_name');
            const lastName = params.get('last_name');
            const username = params.get('username');
            const photoUrl = params.get('photo_url');

            if (id && authDate && hash) {
                const authData = {
                    id,
                    authDate,
                    hash,
                    firstName: firstName ?? null,
                    lastName: lastName ?? null,
                    username: username ?? null,
                    photoUrl: photoUrl ?? null,
                };

                try {
                    const response = await fetch(`${BACKEND_API_URL}/api/auth/login`, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json',
                        },
                        body: JSON.stringify(authData),
                    });

                    const data = await response.json();

                    if (data.token) {
                        localStorage.setItem('token', data.token);
                        dispatch(setAuthData({ token: data.token }));

                        // Асинхронное ожидание до записи токена в localStorage
                        await new Promise(resolve => setTimeout(resolve, 100));
                        navigate('/');
                    } else {
                        console.error('Authentication failed');
                        navigate('/login');
                    }
                } catch (error) {
                    console.error('Error:', error);
                    navigate('/login');
                }
            } else {
                console.error('Missing auth data');
                navigate('/login');
            }
        };

        handleAuth();
    }, [dispatch, navigate]);

    return <div>Авторизация...</div>;
};

export default HandleAuth;
